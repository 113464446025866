import React from "react";
import { Layout } from "antd";
import ImageUpload from "./ImageUpload";
import Navbar from "./Components/Navbar/index";
import "./App.css";
import { Content } from "antd/es/layout/layout";

const { Header, Footer } = Layout;

function Home() {
  return (
    <Layout>
      <Header>
        <Navbar />
      </Header>
      <Content>
        <ImageUpload />
      </Content>
      <Footer style={{ color: "#fff", padding: "2rem" }}>
        <div style={{ textAlign: "center" }}>
          <div style={{ padding: "1rem" }}>
            <img
              src="/socials.png"
              style={{ width: "16rem" }}
              alt="Social Media Icons"
            />
          </div>
          <div style={{ padding: "1rem" }}>
            <h3 style={{ margin: "0 0 0.25rem 0" }}>ติดต่อเรา</h3>
            <h2 style={{ margin: "0 0 0.5rem 0" }}>
              บริษัท พร้อม ดิจิตอล จำกัด
            </h2>
            <div style={{ margin: "0 0 0.25rem 0" }}>
              อาคารสาทร สแควร์ ชั้น 37
            </div>
            <div style={{ margin: "0 0 0.25rem 0" }}>
              เลขที่ 98 ถนนสาธรเหนือ แขวงสีลม เขตบางรัก กรุงเทพมหานคร 10120
            </div>
            <div style={{ margin: "0 0 0.25rem 0" }}>
              โทรศัพท์: 02-105-6300 &nbsp; โทรศัพท์มือถือ: 099-999-9999
            </div>
          </div>
        </div>
      </Footer>
    </Layout>
  );
}

export default Home;
