import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Image,
  notification,
} from "antd";
import axios from "axios";
import "./Login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post("/login", values);
      const { access_token, expires_in, refresh_token, refresh_expires_in } =
        response.data;
      localStorage.setItem("promjodd_access_token", access_token);
      localStorage.setItem("promjodd_refresh_token", refresh_token);
      navigate("/");
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: error?.response?.data?.message,
      });
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Row>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <div />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={10} className="left-section">
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "2%",
              zIndex: 1,
            }}
          >
            <img
              src="/logo@1x.png"
              className="image"
              alt="Logo"
              style={{ marginRight: "0.5rem" }}
            />
            <div style={{ color: "#FFF" }}>
              <h1 className="light-font" style={{ margin: 0 }}>
                พร้อมจอด
              </h1>
              <p
                className="light-font"
                style={{ margin: 0, color: "#FF9B00", fontSize: "0.75rem" }}
              >
                SMART PARKING NETWORK
              </p>
            </div>
          </div>
          <Image
            src="car-bg.png"
            alt="Dummy Image"
            preview={false}
            className="dummy-image"
          />
          <div className="overlay-text">
            <h1 className="light-font">Welcome</h1>
            <p className="light-font">
              To keep connected with us please login with your personal info
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="right-section">
          <div className="login-form">
            <div
              className="logo-mobile"
              style={{
                zIndex: 1,
                margin: "2rem",
                textAlign: "center",
              }}
            >
              <img src="/logo@1x.png" className="image" alt="Logo" />
              <div style={{ color: "#FFF" }}>
                <h1
                  className="light-font"
                  style={{ fontSize: "2rem", margin: 0 }}
                >
                  พร้อมจอด
                </h1>
                <p
                  className="light-font"
                  style={{ margin: 0, color: "#FF9B00", fontSize: "0.75rem" }}
                >
                  SMART PARKING NETWORK
                </p>
              </div>
            </div>
            <h1 className="light-font">Log in(v1)</h1>
            <p className="light-font">
              Don't have an account?{" "}
              <a
                className="login-form-forgot"
                href="/"
                style={{ display: "contents", color: "#FFB41D" }}
              >
                Create an account
              </a>
            </p>
            <p className="light-font" style={{ marginBottom: "2rem" }}>
              it will take less than a minute.
            </p>
            <Form name="loginForm" onFinish={handleLogin}>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username",
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  size="large"
                  style={{ fontSize: "15px" }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                style={{ marginBottom: "0.5rem" }}
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  style={{ fontSize: "15px" }}
                />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox style={{ color: "#6B6B6B" }}>Remember me</Checkbox>
                </Form.Item>
                <a className="login-form-forgot" href="/">
                  Forgot password?
                </a>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  size="large"
                  style={{ marginBottom: "1rem" }}
                  block
                >
                  Sign in
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{ backgroundColor: "#FF0000", marginBottom: "2rem" }}
                  onClick={() => navigate("/")}
                  block
                >
                  Cancel
                </Button>
                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#6B6B6B", marginRight: "0.5rem" }}>
                    Don't have an account?
                  </span>
                  <a className="" href="/">
                    Signup
                  </a>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
