import React from "react";
import { Layout } from "antd";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import logoImg from '@/assets/images/logo.png'
import ImageUpload from "./ImageUpload";
import Navbar from "./Components/Navbar/index";
import "./App.css";
import { Content } from "antd/es/layout/layout";
import Home from "./Home";
import Login from "./Login";

const { Header, Footer } = Layout;

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
