import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import RightMenu from "./RightMenu";
import { Menu, Drawer, Button, Dropdown, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";

const Navbar = () => {
  const [userClaims, setUserClaims] = useState(null);
  const [visible, setVisible] = useState(false);
  const [newWindow, setNewWindow] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("promjodd_access_token");
    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken);
        console.log(decodedToken);

        const expirationTimeMs = decodedToken?.exp * 1000;
        const currentTimeMs = Date.now();
        const isExpired = currentTimeMs > expirationTimeMs;
        console.log(
          `isExpired: ${isExpired}, ${currentTimeMs} > ${expirationTimeMs}`
        );
        setUserClaims(!isExpired ? decodedToken : null);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  const handleLogout = () => {
    if (newWindow && !newWindow.closed) {
      newWindow.close();
    }

    localStorage.removeItem("promjodd_access_token");
    localStorage.removeItem("promjodd_refresh_token");
    setUserClaims(null);
    navigate("/");
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const opendLpr = () => {
    const domain = window.location.hostname.includes("dev")
      ? "https://dev-lpr.promjodd.com/"
      : "https://lpr.promjodd.com/";

    const windowReference = window.open(
      `${domain}?token=${localStorage.getItem(
        "promjodd_refresh_token"
      )}&email=${userClaims?.email}`,
      "_blank"
    );
    setNewWindow(windowReference);

    // const windowReference = window.open(
    //   `https://dev-lpr.promjodd.com/?token=${localStorage.getItem(
    //     "promjodd_refresh_token"
    //   )}&email=${userClaims?.email}`,
    //   "_blank"
    // );
    // setNewWindow(windowReference);

    // const windowReference = window.open(
    //   `http://localhost:3031/?token=${localStorage.getItem(
    //     "promjodd_refresh_token"
    //   )}&email=${userClaims?.email}`,
    //   "_blank"
    // );
    // setNewWindow(windowReference);
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="#">ข้อมูลของฉัน</Link>
      </Menu.Item>
      <Menu.Item key="my_promjodd">
        <Link to="#">พร้อมจอดของฉัน</Link>
      </Menu.Item>
      <Menu.Item key="plate_info" onClick={opendLpr}>
        อ่านป้ายทะเบียนรถ
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        ออกจากระบบ
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="menuBar">
      <div className="logo">
        <a>
          <img src="/logo@2x.png" className="image" alt="Logo" />
        </a>
      </div>
      <div className="menuCon">
        <div className="rightMenu">
          <RightMenu />
        </div>
        <Button className="barsMenu" type="primary" onClick={showDrawer}>
          <span className="barsBtn"></span>
        </Button>
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <div>
            <div className="close-button" onClick={onClose}>
              <span>X</span>
            </div>
            <RightMenu />
          </div>
        </Drawer>
      </div>
      <div className="login-menu">
        <Link
          to="/signup"
          className="signup-link"
          style={{ marginRight: "0.75rem" }}
        >
          <Button shape="round" ghost>
            สร้างบัญชี
          </Button>
        </Link>

        {userClaims ? (
          <Dropdown overlay={profileMenu} placement="bottomLeft">
            <Button
              shape="round"
              icon={
                <Avatar
                  src="https://api.dicebear.com/7.x/miniavs/svg?seed=11"
                  style={{ marginTop: "-0.5rem" }}
                />
              }
              style={{
                backgroundColor: "#FFB41D",
                borderColor: "#ffb41d",
                color: "#FFFFFF",
              }}
            >
              {userClaims?.given_name}
            </Button>
          </Dropdown>
        ) : (
          <Link to="/login">
            <Button
              style={{
                backgroundColor: "#FFB41D",
                borderColor: "#ffb41d",
                color: "#FFFFFF",
              }}
              shape="round"
              icon={<UserOutlined />}
            >
              เข้าสู่ระบบ
            </Button>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
