import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const { useBreakpoint } = Grid;

const RightMenu = () => {
  const { md } = useBreakpoint();
  return (
    <Menu mode={md ? "horizontal" : "inline"} theme="dark">
      <Menu.Item key="home">
        <a href="#">พร้อมจอด</a>
      </Menu.Item>
      <Menu.Item key="solutions">
        <a href="#">SOLUTIONS</a>
      </Menu.Item>
      <Menu.Item key="services">
        <a href="#">บริการ</a>
      </Menu.Item>
      <Menu.Item key="news">
        <a href="#">ข่าวสาร</a>
      </Menu.Item>
      <Menu.Item key="support">
        <a href="#">สนับสนุน</a>
      </Menu.Item>
	  <Menu.Item key="account" className="login-menu">
        <Link to="#">สร้างบัญชี</Link>
      </Menu.Item>
      <Menu.Item key="login" className="login-menu">
        <Link to="/login">เข้าสู่ระบบ</Link>
      </Menu.Item>
    </Menu>
  );
};

export default RightMenu;
