import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Modal } from 'antd';
const { Dragger } = Upload;

const ImageUpload = () => {
  const [loading, setLoading] = useState(false);
  
  const [fileList, setFileList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const [modalContent, setModalContent] = useState([]);
  const [responseData, setResponseData] = useState([]);

  // const [isVerified, setIsVerified] = useState(false);
  const [isRecaptchaVisible, setIsRecaptchaVisible] = useState(true);
  
  const customItemRender = (originNode, file, fileList) => {

    const handleClick = (fileUid) => () => {
      console.log(fileList)
      const filter = fileList?.filter(response => response.uid === fileUid)[0];
      console.log(filter)
      setModalContent(filter?.response);
      setModalVisible(true);
    };

    return (
        <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
          <button onClick={handleClick(file.uid)} style={{ backgroundColor: '#52889F', color: '#FFFFFF', padding: '1rem 2rem', border: 0, borderRadius: '8px', fontSize: '1rem', cursor: 'pointer' }}>R2P</button>
        </div>
    );
  };

  const props = {
    name: 'file',
    multiple: true,
    action: 'upload',
    beforeUpload: (file, fileList) => {
      // Define your file size limit in bytes (500KB)
      const maxSizeBytes = 500 * 1024;
      if (file.size > maxSizeBytes) {
        message.error(`${file.name} file exceeds the size limit (500KB).`);
        return false;
      }
      return true; 
    },
    onChange(info) {

      const { status, response, fileList } = info.file;

      // if (status !== 'uploading') {
      //   console.log(status)
      //   console.log(info.file, info.fileList);
      // }

      const elements = document.querySelectorAll('.ant-upload-list');
      elements.forEach((element, index) => {
        element.style.display = 'none'
      });

      if (status === 'done') {
        console.log(status)
        // console.log(fileList)
        console.log(info.fileList);

        if (info.fileList) {

          const sortByLastModified = (a, b) => {
            return Number(b.lastModified) - Number(a.lastModified);
          };

          const sortByUid = (a,b) => {

            const aParts = a.uid.split("-");
            const aLastValue = aParts[aParts.length - 1];

            const bParts = b.uid.split("-");
            const bLastValue = bParts[bParts.length - 1];

            console.log(`${Number(bLastValue) - Number(aLastValue)}`)

            return Number(bLastValue) - Number(aLastValue);
          };

          info.fileList.sort(sortByUid);
          setFileList(info.fileList);

          message.success(`${info.file.name} file uploaded successfully.`);

          setTimeout(() => {
            const elements = document.querySelectorAll('.ant-upload-list-item-container');
            elements.forEach((element, index) => {
              // if (index > 4) {
              //   element.style.display = 'none';
              // }
              if (index > 0) {
                element.style.display = 'none';
              }
            });
            console.log(elements);

            const nodes = document.querySelectorAll('.ant-upload-list');
            nodes.forEach((node, index) => {
              node.style.display = '';
            });

            // Open modal immediately once the upload successfully
            setModalVisible(true);

          }, 1000);

        }

        setResponseData((prevResponses) => [...prevResponses, response]);
        setModalContent(response);

      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);

        //setResponseData(response)
        setFileList(fileList);
        console.log(response);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },

    itemRender: customItemRender,
  };

  const customStyle = {
    backgroundImage: 'url("LPR_BACKGROUND.png")',
    // backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
    padding: '6rem'
  };

  const handleRemoveFile = (file) => {
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setModalContent([]);
  };
  
  const handleRecaptchaVerify = (response) => {
    // setIsVerified(true);
    setIsRecaptchaVisible(false);
  };

  return (
    <div style={{ position: 'relative' }}>

      {isRecaptchaVisible && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
          <ReCAPTCHA
            sitekey="6Lc64TEoAAAAAOFGSO0epvHbuvv4K_LFYJjGD5OZ"
            onChange={handleRecaptchaVerify}
          />
        </div>
      )} 

      {isRecaptchaVisible && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '97%',
            backdropFilter: 'blur(3px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
        </div>
      )}

      {/* <Dragger {...props} disabled={loading} style={customStyle}> */}
      <Dragger {...props} disabled={loading}>
        <p className="ant-upload-drag-icon">
          {/* <InboxOutlined /> */}
          <img src="/photo.png"/>
        </p>
        <p className="ant-upload-text" style={{ fontWeight: 'bold', color: '#fff' }}>Drop your image here, or <span style={{ color: '#F3960A' }}>browse</span></p>
        {/* <p className="ant-upload-hint">
          Maximum upload file size: <span style={{ fontWeight: 'bold', color: '#ff4d4f' }}>500KB</span>
        </p> */}
        {loading && <p>Uploading...</p>}
      </Dragger>

      <Modal
        visible={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={800}
      >
        <div style={{ fontSize: '1rem' }}>
          <div style={{ padding: '1.5rem', backgroundColor: '#4A839A', color: '#fff', height: '5rem', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
            <span className='light-font' style={{ margin: 0 }}>ข้อมูลตัวรถ</span>
            <h2 style={{ margin: 0 }}>{modalContent?.apiResponse?.lprData?.data.vehBrand}</h2>
          </div>
          <div style={{ backgroundColor: '#fff', padding: '1.5rem', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}> 
              {modalContent?.apiResponse?.storageData?.preSignedUrl && (
                <img
                  src={modalContent?.apiResponse?.storageData?.preSignedUrl}
                  alt="Uploaded Image"
                  style={{ maxWidth: '100%', borderRadius: '8px', marginTop: '-3.5rem' }}
                />
              )}
            <div style={{ margin: 0 }}>ข้อมูลทะเบียนรถ</div>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div style={{ flex: '1', border: '1px solid #d4d0d0', borderRadius: '8px', padding: '0.75rem' }}>
                <div style={{ textAlign: 'center' }}>{modalContent?.apiResponse?.lprData?.data.license}</div><div className='light-font' style={{ textAlign: 'center' }}>หมายเลขทะเบียน : </div>
              </div>
              <div style={{ flex: '1', border: '1px solid #d4d0d0', borderRadius: '8px', padding: '0.75rem' }}>
                <div style={{ textAlign: 'center' }}>{modalContent?.apiResponse?.lprData?.data.province}</div><div className='light-font' style={{ textAlign: 'center' }}>จังหวัด : </div>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #E3E3E3', padding: '1rem' }}>ประเภท : <span className='light-font' style={{ paddingLeft: '0.5rem' }}>{modalContent?.apiResponse?.lprData?.data.vehClass}</span></div>
            <div style={{ borderBottom: '1px solid #E3E3E3', padding: '1rem' }}>สี : <span className='light-font' style={{ paddingLeft: '0.5rem' }}>{modalContent?.apiResponse?.lprData?.data.vehColor}</span></div>
            <div style={{ borderBottom: '1px solid #E3E3E3', padding: '1rem' }}>ยี่ห้อ : <span className='light-font' style={{ paddingLeft: '0.5rem' }}>{modalContent?.apiResponse?.lprData?.data.vehBrand}</span></div>
            <div style={{ padding: '1rem' }}>รุ่น : </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImageUpload;